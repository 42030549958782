import { ApiStore, Widget, rotationModes } from '@hart-estate/widget';
import '../assets/sass/index.sass';

const Config = {
  urls: {
    production: 'https://backend.estate.hart-digital.com',
    stage: 'https://backend-stage.estate.hart-digital.com',
  },
  hidePreloaderDelay: 500,
};

const hidePreloader = () => {
  const preloader = document.querySelector('.widget-preloader-frame');
  if (preloader) {
    preloader.classList.add('hidden');
  } else {
    console.error('Preloader not found!');
  }
};

const updateScaleText = (scaleButton) => {
  const img = scaleButton.querySelector('img');
  const span = scaleButton.querySelector('span');
  if (img && span) {
    const dataTail05 = 'Ii8+Cjwvc3ZnPgo=';
    span.innerHTML = img.src.endsWith(dataTail05) ? '0.5x' : '1x';
  }
};

const removeButtonsAndHideContainer = () => {
  const controlsContainer = document.querySelector('.widget-tab__panorama-controls');
  const rotationButton = document.querySelector('.widget-tab__panorama-controls-button--rotation');
  const rulerButton = document.querySelector('.widget-tab__panorama-controls-button--ruler');

  const button3D = document.querySelectorAll('.widget-tab-button')[1];
  button3D.addEventListener('click', () => {
    const interval = setInterval(() => {
      const scaleButton = document.querySelector('.widget-tab__panorama-controls-button--scale');
      const rotationButton = document.querySelector('.widget-tab__panorama-controls-button--rotation');
      if (scaleButton && rotationButton) {
        clearInterval(interval);
        rotationButton.remove();
      }
    }, 0.01)
    rotationButton.remove();
  })

  if (rotationButton) {
    rotationButton.remove();
  }

  if (controlsContainer && !rulerButton) {
    controlsContainer.style.display = 'none';
  }
};

const startRulerTime = () => {
  const rulerTime = setInterval(() => {
    const select = document.querySelector('.floors-select');
    const ruler = document.querySelector('.widget-tab__panorama-controls-button--ruler');
    if (select && ruler) {
      select.before(ruler);
      clearInterval(rulerTime);
    }
  }, 100);
};

const startScaleTime = () => {
  const scaleTime = setInterval(() => {
    const select = document.querySelector('.widget-tab-button--active');
    const scale = document.querySelector('.widget-tab__panorama-controls-button--scale');
    if (select && scale) {
      select.after(scale);

      if (!scale.querySelector('span')) {
        const span = document.createElement('span');
        scale.appendChild(span);
        updateScaleText(scale);
      }

      clearInterval(scaleTime);
    }
  }, 100);
};

const startSelectFloorTime = () => {
  const selectTime = setInterval(() => {
    const buttons = document.querySelector('.widget-tab-buttons');
    const select = document.querySelector('.floors-select');
    if (select && buttons) {
      buttons.append(select);
      clearInterval(selectTime);
    }
  }, 100);
};

const copyWidgetEvents = () => {
  document.querySelector('.widget-tab-buttons .widget-tab__isometry-arrow--prev').addEventListener('click', () => {
    document.querySelector('.widget-tab__rotation .widget-tab__isometry-arrow--prev').click();
    document.querySelector('.widget-tab-buttons span').innerHTML = document.querySelector('.widget-tab__rotation span').innerHTML;
  });
  document.querySelector('.widget-tab-buttons .widget-tab__isometry-arrow--next').addEventListener('click', () => {
    document.querySelector('.widget-tab__rotation .widget-tab__isometry-arrow--next').click();
    document.querySelector('.widget-tab-buttons span').innerHTML = document.querySelector('.widget-tab__rotation span').innerHTML;
  });
  document.querySelector('.widget-tab__isometry-button').addEventListener('click', () => {
    document.querySelector('.widget-tab-buttons .widget-tab__isometry-arrows').remove();
    startArrowsTime();
  });
};

const startArrowsTime = () => {
  const arrowsTime = setInterval(() => {
    const buttons = document.querySelector('.widget-tab-buttons');
    const arrows = document.querySelector('.widget-tab__isometry-arrows');
    if (buttons && arrows) {
      buttons.append(arrows.cloneNode(true));
      copyWidgetEvents();
      clearInterval(arrowsTime);
    }
  }, 100);
};

const redesignButtons = () => {
  startSelectFloorTime();
  startRulerTime();
  startScaleTime();
  startArrowsTime();

  const button2D = document.getElementsByClassName('widget-tab-button')[0];
  const button3D = document.getElementsByClassName('widget-tab-button')[1];

  button2D.innerHTML = '2D';
  button3D.innerHTML = '3D';

  document.addEventListener('click', (e) => {
    if (e.target.innerHTML === '2D') {
      const buttons = document.querySelector('.widget-tab-buttons');
      const ruler = document.querySelector('.widget-tab__panorama-controls-button--ruler');
      const scale = document.querySelector('.widget-tab__panorama-controls-button--scale');
      if (ruler) buttons.removeChild(ruler);
      buttons.removeChild(scale);
      startRulerTime();
      startScaleTime();
    }
    else if (e.target.innerHTML === '3D') {
      const buttons = document.querySelector('.widget-tab-buttons');
      const arrows = document.querySelector('.widget-tab__isometry-arrows');
      buttons.removeChild(arrows);
      startArrowsTime();
    }
    else if (e.target.className.endsWith('controls-button--scale')) {
      updateScaleText(e.target);
    }
  });
};

const observeDOM = (callback) => {
  const observer = new MutationObserver(() => {
    if (document.querySelector('.widget-tab__panorama-controls-button--scale')) {
      observer.disconnect();
      callback();
    }
  });

  observer.observe(document.body, {
    childList: true,
    subtree: true,
  });
};

const getParams = () => {
  const { searchParams } = new URL(document.location);
  return {
    planId: searchParams.get('id'),
    crmPlanId: searchParams.get('crmPlanId'),
    env: (searchParams.get('env') || 'production').toLowerCase(),
    lang: (searchParams.get('lang') || 'en').toLowerCase(),
    primaryCameraPointId: searchParams.get('primaryCameraPointId'),
  }
}

const createWidget = async () => {
  observeDOM(removeButtonsAndHideContainer);

  const { planId, crmPlanId, env, lang, primaryCameraPointId } = getParams();

  const apiUrl = Config.urls[env] || Config.urls['production'];
  const widgetApiHandler = new ApiStore(apiUrl);
  const planData = crmPlanId
    ? await widgetApiHandler.loadCrmWidgetData(crmPlanId)
    : await widgetApiHandler.loadWidgetData(planId);

  const isRuLang = lang === 'ru';
  if (isRuLang) {
    document.getElementById('widget').classList.add('widget-ru');
  }

  const options = {
    ...planData.parsed,
    API_URL: apiUrl,
    rotationMode: rotationModes.DEFAULT,
    tabs: ['panorama', 'rotation'],
    locale: isRuLang ? 'evometaRu' : 'evometa',
    colors: {
      main: '#FFD180',
      mainText: '#252525',
    },
    dictionaryOverrides: {
      floor: '$0',
      floorNumberEndings: {
        1: 'Ground Floor',
        2: 'First Floor',
        3: 'Second Floor'
      }
    },
    floorNumberEndingsVisible: true,
    scales: ['x05', 'x1'],
    forceTopViewOnMap: false,
    primaryCameraPointId,
    designType: 'standard',
    tabsButtonsDesign: 'two',
    cursorType: 'circle'
  };

  new Widget('#widget', options);
};

createWidget()
  .then(() => {
    redesignButtons();
    setTimeout(hidePreloader, Config.hidePreloaderDelay);
  })
  .catch((e) => {
    hidePreloader();
    const errorMessage = document.querySelector('.error-message');
    if (errorMessage) {
      errorMessage.innerHTML = `:(`;
    }
  });
